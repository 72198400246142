// src/App.js
import React from 'react';
import AudioRecorder from './AudioRecorder';

function App() {
  // Extract clientKey from the URL query parameters
  const urlParams = new URLSearchParams(window.location.search);
  const clientKey = urlParams.get('clientKey') || 'test_key_24112024';

  return (
    <AudioRecorder clientKey={clientKey} />
  );
}

export default App;
