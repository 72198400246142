// SocketManager.js

import { io } from 'socket.io-client';

class SocketManager {
  constructor(options = {}) {
    this.client_key = options.client_key || '';
    this.sampleRate = options.sampleRate || 16000;
    this.user_id = options.user_id || '';
    this.user_name = options.user_name || '';
    this.assessment_link = options.assessment_link || '';
    this.allowed_users = options.allowed_users || [];
    this.interview_type = options.interview_type || 'demo'; // Added interview_type
    this.backendUrl = options.backendUrl || 'https://demo-runner-service-test-421658591024.us-central1.run.app'; //'http://localhost:5100'; //'https://demo-runner-service-test-421658591024.us-central1.run.app'; //'http://localhost:5100';  //'https://demo-runner-service-test-421658591024.us-central1.run.app'; //'https://demo-runner-service-test-copy-421658591024.us-central1.run.app'; //    // 
    this.namespace = options.namespace || '/audio';
    this.onPlayAssistantAudio = options.onPlayAssistantAudio;
    this.onTranscription = options.onTranscription;
    this.onStopRecording = options.onStopRecording;
    this.onInterviewComplete = options.onInterviewComplete;
    this.onConnect = options.onConnect;
    this.onConnectError = options.onConnectError;
    this.onTextResponse = options.onTextResponse;
    this.onRequestTextInput = options.onRequestTextInput;
    this.onPlayVideo = options.onPlayVideo;
    this.socket = null;
  }

  initializeSocket() {
    // Prepare query parameters
    const queryParams = {};

    if (this.client_key) queryParams.client_key = this.client_key;
    if (this.sampleRate) queryParams.sampleRate = this.sampleRate;
    if (this.user_id) queryParams.user_id = this.user_id;
    if (this.user_name) queryParams.user_name = this.user_name;
    if (this.assessment_link) queryParams.assessment_link = this.assessment_link;
    if (this.allowed_users && this.allowed_users.length > 0)
      queryParams.allowed_users = this.allowed_users;
    if (this.interview_type) queryParams.interview_type = this.interview_type;

    // Connect to the specified namespace
    this.socket = io(`${this.backendUrl}${this.namespace}`, {
      path: '/socket.io/',
      transports: ['websocket'],
      query: queryParams,
    });

    // Set up event listeners
    this.socket.on('play_assistant_audio', (data) => {
      console.log('Received play_assistant_audio event from server:', data);
      if (this.onPlayAssistantAudio) {
        this.onPlayAssistantAudio(data);
      }
    });

    this.socket.on('transcription', (data) => {
      console.log('Received transcription:', data);
      if (data && data.text && this.onTranscription) {
        this.onTranscription(data.text);
      }
    });

    this.socket.on('interview_complete', () => {
      console.log('Interview is complete.');
      if (this.onInterviewComplete) {
        this.onInterviewComplete();
      }
    });

    this.socket.on('text_response', (data) => {
      console.log('Received text_response event from server:', data);
      if (this.onTextResponse) {
        this.onTextResponse(data);
      }
    });

    this.socket.on('request_text_input', (data) => {
      console.log('Received request_text_input event from server:', data);
      if (this.onRequestTextInput) {
        this.onRequestTextInput(data);
      }
    });

    this.socket.on('play_video', (data) => {
      console.log('Received play_video event from server:', data);
      console.log('this.onPlayVideo:', this.onPlayVideo); // Add this line
      if (this.onPlayVideo) {
        this.onPlayVideo(data);
      } else {
        console.warn('onPlayVideo is not defined in SocketManager.');
      }
    });

    this.socket.on('connect', () => {
      console.log('SocketManager connected.');
      if (this.onConnect) this.onConnect();
    });

    this.socket.on('connect_error', (error) => {
      console.error('SocketManager connection error:', error);
      if (this.onConnectError) this.onConnectError(error);
    });
  }

  sendAudioData(pcmData) {
    if (this.socket && this.socket.connected) {
      this.socket.emit('audio', pcmData);
    }
  }

  sendTextData(text) {
    if (this.socket && this.socket.connected) {
      this.socket.emit('text', { text });
      console.log('Sent text data to backend:', text);
    } else {
      console.warn('Socket not connected. Cannot send text data.');
    }
  }

  restartRecording() {
    if (this.socket && this.socket.connected) {
      this.socket.emit('restart_recording');
      console.log("Sent 'restart_recording' event to backend.");
    }
  }

  pauseRecording() {
    if (this.socket && this.socket.connected) {
      this.socket.emit('pause_recording');
      console.log("Sent 'pause_recording' event to backend.");
    }
  }

  manualStopRecording() {
    if (this.socket && this.socket.connected) {
      this.socket.emit('manual_stop_recording');
      console.log("Sent 'manual_stop_recording' event to backend.");
    } else {
      console.warn('Socket not connected. Cannot send manual_stop_recording event.');
    }
  }  

  resumeRecording() {
    if (this.socket && this.socket.connected) {
      this.socket.emit('resume_recording');
      console.log("Sent 'resume_recording' event to backend.");
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      console.log('Socket.IO connection closed.');
    }
  }
}

export default SocketManager;
