// StyledComponents.jsx

import { styled, keyframes, CircularProgress } from '@mui/material';

// Pulsating and Morphing Animation for a Dynamic Effect
const pulseAndMorphKeyframes = keyframes`
  0%, 100% {
    transform: scale(1);
    border-radius: 50%;
    background-position: 0% 50%;
  }
  50% { 
    transform: scale(1.1);
    border-radius: 48% 52% 50% 50% / 50% 50% 52% 48%; /* Subtle shape morphing */
    background-position: 100% 50%;
  }
`;

// Enhanced PulsatingCircle with Gradient, Shadow, and Morphing Effect
export const PulsatingCircle = styled('div')(({ theme }) => ({
  width: '160px',
  height: '160px',
  background: `linear-gradient(270deg, ${theme.palette.primary.light}, ${theme.palette.secondary.main}, ${theme.palette.primary.dark})`,
  backgroundSize: '200% 200%',
  borderRadius: '50%',
  animation: `${pulseAndMorphKeyframes} 3s ease-in-out infinite`,
  boxShadow: `0 0 25px ${theme.palette.primary.light}, 0 0 50px ${theme.palette.primary.dark}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px', // Added margin for spacing
}));




// Enhanced Rotating Animation for Loading
const rotateKeyframes = keyframes`
  from { 
    transform: rotate(0deg); 
  }
  to { 
    transform: rotate(360deg); 
  }
`;

// Enhanced RotatingCircle with Gradient and Glow
export const RotatingCircle = styled(CircularProgress)(({ theme }) => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100px !important', // Increased size
  height: '100px !important',
  zIndex: 1500,
  '& .MuiCircularProgress-svg': {
    stroke: theme.palette.info.main,
    strokeLinecap: 'round',
    strokeWidth: 5,
    animation: `${rotateKeyframes} 2.5s linear infinite`,
    filter: 'drop-shadow(0 0 10px rgba(33, 150, 243, 0.7))',
  },
}));

// Custom animation for bars to grow in a wave pattern
const barsWaveAnimation = (scaleStart, scaleEnd) => keyframes`
  0%, 100% {
    transform: scaleY(${scaleStart});
  }
  50% {
    transform: scaleY(${scaleEnd});
  }
`;

// Enhanced AudioBar with Gradient and Smooth Animation in a wave-like pattern
export const AudioBar = styled('div')(({ theme, delay, scaleStart, scaleEnd }) => ({
  width: '22%',
  margin: '0 5px', // Adjusted margins for spacing
  background: `linear-gradient(to top, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
  borderRadius: '4px',
  transformOrigin: 'bottom', // Bars grow from the bottom
  transform: `scaleY(${scaleStart})`,
  height: '100%',
  animation: `${barsWaveAnimation(scaleStart, scaleEnd)} 1.2s infinite ease-in-out`,
  animationDelay: `${delay}s`,
}));

// Enhanced AudioBars Container with Background and Border Radius
export const AudioBars = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center', // Align items to the center for the vertical wave
  height: '120px', // Height of the container
  width: '100%', // Full width
  padding: '10px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '10px',
  boxShadow: `0 4px 10px rgba(0, 0, 0, 0.1)`,
}));

// Enhanced SimpleCircle with Gradient and Hover Effect
export const SimpleCircle = styled('div')(({ theme, color }) => ({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  background: `linear-gradient(135deg, ${color}, ${theme.palette.secondary.main})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  fontSize: '20px',
  fontWeight: 'bold',
  margin: '20px auto',
  boxShadow: `0 0 15px rgba(0, 0, 0, 0.2)`,
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: `0 0 25px rgba(0, 0, 0, 0.3)`,
  },
}));
