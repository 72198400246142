// RatingComponent.js

import React, { useState } from 'react';

const RatingComponent = ({ onSubmit }) => {
  const [rating, setRating] = useState(0); // User's selected rating (0-5)
  const [hoverRating, setHoverRating] = useState(0); // Rating during hover
  const [comment, setComment] = useState(''); // User's comment

  const handleStarClick = (value) => {
    setRating(value);
  };

  const handleStarMouseEnter = (value) => {
    setHoverRating(value);
  };

  const handleStarMouseLeave = () => {
    setHoverRating(0);
  };

  const handleSubmit = () => {
    if (rating === 0) {
      alert('Please select a rating before submitting.');
      return;
    }
    // Call the onSubmit callback with the rating and comment
    onSubmit({ rating, comment });
    // Optionally reset the form
    setRating(0);
    setComment('');
  };

  return (
    <div style={containerStyle}>
      <h2>Rate the Demo</h2>
      <div style={starsContainerStyle}>
        {[1, 2, 3, 4, 5].map((value) => (
          <svg
            key={value}
            onClick={() => handleStarClick(value)}
            onMouseEnter={() => handleStarMouseEnter(value)}
            onMouseLeave={handleStarMouseLeave}
            viewBox="0 0 24 24"
            width="48"
            height="48"
            style={{
              cursor: 'pointer',
              fill:
                (hoverRating || rating) >= value ? '#FFD700' : 'transparent',
              stroke: '#FFD700',
            }}
          >
            <polygon points="12,2 15,9 22,9 17,14 19,21 12,17 5,21 7,14 2,9 9,9" />
          </svg>
        ))}
      </div>
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Optional comment..."
        style={textareaStyle}
      />
      <button onClick={handleSubmit} style={buttonStyle}>
        Submit Feedback
      </button>
    </div>
  );
};

// Styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const starsContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '20px',
};

const textareaStyle = {
  width: '100%',
  maxWidth: '600px',
  padding: '10px',
  fontSize: '16px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  resize: 'vertical',
  boxSizing: 'border-box',
  marginBottom: '20px',
};

const buttonStyle = {
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  borderRadius: '4px',
  border: 'none',
  backgroundColor: '#28a745',
  color: '#fff',
  transition: 'background-color 0.3s ease',
};

export default RatingComponent;
