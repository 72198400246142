import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RotatingCircle, PulsatingCircle, AudioBars, AudioBar } from '../Styles/StyledComponents';

const AudioVisualization = ({
  isInitializing,
  isAudioPlaying,
  isListening,
  bars,
  currentQuestion,
  isProcessEnded,
}) => {
  const theme = useTheme();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    if (isInitializing) {
      // Start countdown when initialization begins
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 0) {
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval); // Cleanup on unmount or when initialization ends
    }
  }, [isInitializing]);

  return (
    <>
      {/* Display RotatingCircle and initialization text with countdown */}
      {isInitializing && (
        <div style={containerStyle}>
          <RotatingCircle />
          <div>
            <p>Your AI agent is being prepared. Please get ready to dive in!</p>
            <p>Ensure that you are in a quiet environment with a stable internet connection.</p>
            <h1 style={countdownStyle}>{countdown}</h1>
          </div>
        </div>
      )}

      {/* Display when assistant is speaking */}
      {isAudioPlaying && !isListening && !isInitializing && (
        <div style={containerStyle}>
          <p>Sam is speaking...</p>
          <div style={visualizationWrapperStyle}>
            <AudioBars>
              {bars.map((bar, index) => (
                <AudioBar
                  key={index}
                  delay={bar.delay}
                  scaleStart={bar.scaleStart}
                  scaleEnd={bar.scaleEnd}
                />
              ))}
            </AudioBars>
          </div>
        </div>
      )}

      {/* Display when recording is active */}
      {isListening && !isAudioPlaying && !isInitializing && (
        <div style={containerStyle}>
          <p>Sam is listening...</p>
          <PulsatingCircle />
        </div>
      )}

      {/* Display the current question */}
      {!isInitializing && !isProcessEnded && currentQuestion && (
        <div style={questionStyle}>
          <h3> </h3>
          <p>{currentQuestion}</p>
        </div>
      )}
    </>
  );
};

// Styles for containers
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const visualizationWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

const questionStyle = {
  textAlign: 'center',
  marginTop: '20px',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
};

const countdownStyle = {
  fontSize: '4rem',
  fontWeight: 'bold',
  color: '#0099FF',
  marginTop: '20px',
};

AudioVisualization.propTypes = {
  isInitializing: PropTypes.bool.isRequired,
  isAudioPlaying: PropTypes.bool.isRequired,
  isListening: PropTypes.bool.isRequired,
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      scaleStart: PropTypes.number.isRequired,
      scaleEnd: PropTypes.number.isRequired,
      delay: PropTypes.number.isRequired,
    })
  ).isRequired,
  currentQuestion: PropTypes.string.isRequired,
  isProcessEnded: PropTypes.bool.isRequired,
};

export default AudioVisualization;
