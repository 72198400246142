import React, { useState } from 'react';
import './EmailCapture.css';

const EmailCapture = ({ pageOrigin }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('https://email-capturing-service-421658591024.us-central1.run.app/submit-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, pageOrigin }),
      });

      if (!response.ok) throw new Error('Network response was not ok');

      setEmail('');
      setSubmitted(true);
      setError('');
    } catch (err) {
      console.error('Failed to submit email:', err);
      setError('Failed to submit email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="email-capture-container">
      {!submitted ? (
        <>
          <p className="email-message">
            Please share your email to communicate with the team or receive more information after this chat
          </p>
          {loading && (
            <p className="email-validating">Validating your email...</p>
          )}
          {!loading && (
            <form onSubmit={handleSubmit} className="email-form">
              <input
                type="email"
                placeholder="Your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="email-input"
                required
              />
              <button type="submit" className="email-submit-button">
                Submit
              </button>
              {error && <div className="email-error">{error}</div>}
            </form>
          )}
        </>
      ) : (
        <p className="email-success">Thank you! We'll be in touch soon.</p>
      )}
    </div>
  );
};

export default EmailCapture;
