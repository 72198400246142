// useAudioEngine.js

import { useRef, useState, useEffect } from 'react';
import SocketManager from './SocketManager';

/**
 * Custom hook to manage audio recording, video playback, and text input functionalities.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {string} params.userName - Name of the user.
 * @param {Array<string>} params.allowedUsers - List of allowed users.
 * @param {string} params.link - Assessment link.
 * @param {function} params.onTranscriptionUpdate - Callback for transcription updates.
 * @param {function} params.onProcessEnd - Callback when the interview process ends.
 * @param {function} params.onAudioPlayingChange - Callback when audio playback state changes.
 * @param {function} params.onListeningChange - Callback when listening state changes.
 * @param {function} params.setCurrentQuestion - Function to set the current question.
 * @param {string} params.backendUrl - URL of the backend server.
 * @param {function} [params.onTextResponse] - Optional callback for text responses from the backend.
 * @param {function} [params.onPlayVideo] - Optional callback for playing video when 'play_video' event is received.
 *
 * @returns {Object} - Contains references and methods to control the audio, video, and text functionalities.
 */
const useAudioEngine = ({
  clientKey,
  userName,
  allowedUsers,
  link,
  onTranscriptionUpdate,
  onProcessEnd,
  onAudioPlayingChange,
  onListeningChange,
  setCurrentQuestion,
  backendUrl,
  onTextResponse,
  onPlayVideo,
  autoStart = true,
  interviewType,
  setVideoData
}) => {
  const [isInterviewStarted, setIsInterviewStarted] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [isInterviewComplete, setIsInterviewComplete] = useState(false);
  const [showTextInput, setShowTextInput] = useState(false); // Controls text input visibility

  const audioContextRef = useRef(null);
  const processorRef = useRef(null);
  const sourceRef = useRef(null);
  const streamRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const videoPlayerRef = useRef(null); // Reference for video playback
  const isRecordingRef = useRef(false);
  const socketManagerRef = useRef(null);

  /**
   * Initializes the Socket.IO connection and sets up audio processing.
   */
  const initializeSocket = async () => {
    console.log('Initializing Socket.IO connection...');
    setIsInitializing(true); // Start initialization

    // Request permission to access the microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone access granted.');

      streamRef.current = stream;

      // Create a new AudioContext
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      const sampleRate = audioContextRef.current.sampleRate;
      console.log('AudioContext sample rate:', sampleRate);

      // Fix #1: Resume the AudioContext if it's suspended (necessary for iOS)
      if (audioContextRef.current.state === 'suspended') {
        console.log('AudioContext is suspended, attempting to resume...');
        try {
          await audioContextRef.current.resume();
          console.log('AudioContext state after resume:', audioContextRef.current.state);
        } catch (e) {
          console.error('Error resuming AudioContext:', e);
        }
      }

      // Fix #2: Create a silent oscillator to kickstart the AudioContext on iOS
      const oscillator = audioContextRef.current.createOscillator();
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = 0; // Set gain to 0 so we don't hear anything
      oscillator.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      oscillator.start();
      oscillator.stop(audioContextRef.current.currentTime + 0.01); // Stop after a short time

      // Initialize SocketManager if not already connected
      if (!socketManagerRef.current) {
        // Prepare options
        const options = {
          client_key: clientKey,
          sampleRate: sampleRate,
          user_name: userName || 'Default User',
          assessment_link: link || 'tmp_link',
          allowed_users: allowedUsers,
          interview_type: interviewType,
          onPlayAssistantAudio: handlePlayAssistantAudio,
          onTranscription: handleTranscription,
          onStopRecording: stopRecording,
          onInterviewComplete: handleInterviewComplete,
          onConnect: handleSocketConnect,
          onConnectError: handleSocketConnectError,
          onTextResponse: handleTextResponse,
          onRequestTextInput: handleRequestTextInput,
          onPlayVideo: socketPlayVideoHandler};

        console.log('SocketManager options:', options);

        socketManagerRef.current = new SocketManager(options);
        socketManagerRef.current.initializeSocket();
      }
    } catch (err) {
      console.error('Error accessing microphone:', err);
      alert('Microphone access is required to proceed.');
    }
  };

  /**
   * Pauses the audio recording without disconnecting the socket and informs the backend.
   */
  const pauseRecording = () => {
    console.log('pauseRecording function called.');

    // Stop audio processing
    if (processorRef.current) {
      processorRef.current.disconnect();
      processorRef.current.onaudioprocess = null;
      processorRef.current = null;
    }
    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current = null;
    }

    isRecordingRef.current = false;
    if (onListeningChange) {
      onListeningChange(false);
    }
    console.log('Recording paused.');

    // Inform backend
    if (socketManagerRef.current) {
      socketManagerRef.current.pauseRecording();
      console.log("Sent 'pause_recording' event to backend.");
    }
  };

  const manualStopRecording = () => {
    console.log('Manually stopping recording and processing input.');
    if (socketManagerRef.current) {
      socketManagerRef.current.manualStopRecording();
      console.log("Sent 'manual_stop_recording' event to backend.");
    } else {
      console.warn('SocketManager is not initialized. Cannot send manual_stop_recording command.');
    }
  };
  

  /**
   * Initializes the AudioContext, requests microphone access,
   * and plays a silent oscillator to unlock audio playback.
   */
    const initializeAudioContextAndMic = async () => {
      console.log('Initializing AudioContext and requesting microphone access...');
  
      // Request microphone access
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log('Microphone access granted.');
        streamRef.current = stream;
      } catch (err) {
        console.error('Error accessing microphone:', err);
        throw err; // Rethrow to handle in caller
      }
  
      // Create or resume AudioContext
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        console.log('AudioContext created.');
      }
  
      if (audioContextRef.current.state === 'suspended') {
        try {
          await audioContextRef.current.resume();
          console.log('AudioContext resumed.');
        } catch (e) {
          console.error('Error resuming AudioContext:', e);
        }
      }
  
      // Play a silent oscillator to unlock audio playback
      const oscillator = audioContextRef.current.createOscillator();
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = 0; // Mute the oscillator
      oscillator.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      oscillator.start();
      oscillator.stop(audioContextRef.current.currentTime + 0.01); // Stop shortly after
      console.log('Played silent oscillator to unlock audio playback.');
    };

  /**
   * Resumes audio recording and informs the backend.
   */
  const resumeRecording = () => {
    console.log('Resuming recording...');

    // Inform backend first
    if (socketManagerRef.current) {
      // Change 'resumeRecording' to 'restartRecording'
      socketManagerRef.current.restartRecording();
      console.log("Sent 'restart_recording' event to backend.");
    }

    // Introduce a 2-second delay before starting recording
    setTimeout(() => {
      console.log('Starting recording after 2-second delay.');
      startRecordingAfterPrompt();
    }, 200);
  };

  /**
   * Handles the event when the assistant audio needs to be played.
   *
   * @param {Object} data - Data containing the audio URL and question text.
   */
  const handlePlayAssistantAudio = (data) => {
    console.log('Handling play assistant audio.');
    setIsInitializing(false); // End initialization when audio starts playing

    // Set the current question text
    setCurrentQuestion(data.question_text || '');

    // Play assistant audio and pause recording
    playAssistantAudio(data.audio_url);
  };

  /**
   * Handles the event when a video needs to be played.
   *
   * @param {Object} data - Data containing the video URL and timing.
   */

  const socketPlayVideoHandler = (data) => {
    console.log('Handling play video from socket.');
    
    // Pause any audio playback
    if (audioPlayerRef.current && !audioPlayerRef.current.paused) {
      audioPlayerRef.current.pause();
      console.log('Paused assistant audio playback.');
    }
    
    // Pause recording
    pauseRecording();

    console.log('onPlayVideo in useAudioEngine:', onPlayVideo);

    if (onPlayVideo) {
      onPlayVideo(data);
    } else {
      console.warn('onPlayVideo is undefined in useAudioEngine.');
    }

    // **Add this block to clear the current question**
    if (setCurrentQuestion) {
      setCurrentQuestion('Preparing next step...');
    }
  };

  

  /**
   * Handles transcription updates from the backend.
   *
   * @param {string} text - The transcribed text.
   */
  const handleTranscription = (text) => {
    if (onTranscriptionUpdate) {
      onTranscriptionUpdate(text);
      console.log('Transcription updated:', text);
    }
  };

  /**
   * Handles the completion of the interview process.
   */
  const handleInterviewComplete = () => {
    console.log('Handling interview completion...');
    stopRecording(); // Ensure recording is stopped
    setIsInterviewComplete(true); // Set interview as complete
    if (onProcessEnd) {
      onProcessEnd();
      console.log('Called onProcessEnd callback.');
    }
  };

  /**
   * Handles successful Socket.IO connection.
   */
  const handleSocketConnect = () => {
    console.log('Socket.IO connection established.');
    // Do not start recording here. Wait for the assistant's prompt.
  };

  /**
   * Handles Socket.IO connection errors.
   *
   * @param {Error} error - The connection error.
   */
  const handleSocketConnectError = (error) => {
    console.error('Socket.IO connection error:', error);
  };

  /**
   * Handles text responses received from the backend.
   *
   * @param {Object} data - Data containing the text response.
   */
  const handleTextResponse = (data) => {
    console.log('Received text response from backend:', data);
    if (onTextResponse) {
      onTextResponse(data);
    }
    // Additional processing can be done here if needed
  };

  /**
   * Handles the backend's request to display the text input field.
   *
   * @param {Object} data - Data containing the request details.
   */
  const handleRequestTextInput = (data) => {
    console.log('Received request_text_input from backend:', data);
    setShowTextInput(true); // Show the text input field
  };

  /**
   * Starts recording audio after receiving a prompt.
   */
// useAudioEngine.js

  const startRecordingAfterPrompt = () => {
    console.log('Attempting to start recording...');

    if (!audioContextRef.current || !streamRef.current) {
      console.warn('Audio context or stream not initialized.');
      return;
    }

    // Ensure no existing processor is active
    if (processorRef.current) {
      processorRef.current.disconnect();
      processorRef.current.onaudioprocess = null;
      processorRef.current = null;
    }

    // Create a MediaStreamAudioSourceNode
    sourceRef.current = audioContextRef.current.createMediaStreamSource(streamRef.current);

    // Create a ScriptProcessorNode
    processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);

    // Fix: Create a GainNode with zero gain to ensure audio processing on iOS
    const zeroGain = audioContextRef.current.createGain();
    zeroGain.gain.value = 0;

    // Connect nodes
    sourceRef.current.connect(processorRef.current);
    processorRef.current.connect(zeroGain);
    zeroGain.connect(audioContextRef.current.destination);

    // Set up audio processing
    processorRef.current.onaudioprocess = (e) => {
      if (socketManagerRef.current && isRecordingRef.current) {
        // Get audio data
        const data = e.inputBuffer.getChannelData(0);

        // Convert to 16-bit PCM
        const pcmData = floatTo16BitPCM(data);

        // Send audio data to the server
        socketManagerRef.current.sendAudioData(pcmData);
        console.log('Sent audio data to backend.');
      }
    };

    isRecordingRef.current = true;
    if (onListeningChange) {
      onListeningChange(true);
    }
    if (onAudioPlayingChange) {
      onAudioPlayingChange(false);
    }
    console.log('Recording started.');
  };


  /**
   * Stops the audio recording and cleans up resources.
   */
  const stopRecording = () => {
    console.log('Stopping recording...');
    if (isRecordingRef.current) {
      // Stop audio processing
      if (processorRef.current) {
        processorRef.current.disconnect();
        processorRef.current.onaudioprocess = null;
        processorRef.current = null;
      }
      if (sourceRef.current) {
        sourceRef.current.disconnect();
        sourceRef.current = null;
      }

      isRecordingRef.current = false;
      if (onListeningChange) {
        onListeningChange(false);
      }
      console.log('Recording stopped.');
    }

    // Disconnect SocketManager
    if (socketManagerRef.current) {
      socketManagerRef.current.disconnect();
      socketManagerRef.current = null;
      console.log('Disconnected SocketManager.');
    }

    // Close the AudioContext
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close();
      audioContextRef.current = null;
      console.log('AudioContext closed.');
    }

    // Stop all audio tracks
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
      console.log('Stopped all audio tracks.');
    }
  };

  /**
   * Interrupts the agent by stopping audio/video playback and resuming recording.
   */
    const interruptAgent = () => {
      console.log('Interrupting agent...');
      // Stop agent's audio playback if any
      if (audioPlayerRef.current && !audioPlayerRef.current.paused) {
        audioPlayerRef.current.pause();
        audioPlayerRef.current.currentTime = 0;
        console.log('Paused assistant audio playback.');
        if (onAudioPlayingChange) {
          onAudioPlayingChange(false);
        }
      }
  
      // Stop video playback if any
      if (videoPlayerRef.current && !videoPlayerRef.current.paused) {
        videoPlayerRef.current.pause();
        videoPlayerRef.current.currentTime = 0;
        console.log('Paused video playback.');
      }
  
      // Hide the video if any
      if (setVideoData) {
        setVideoData(null);
        console.log('Cleared video data.');
      }
  
      // Clear the current question
      if (setCurrentQuestion) {
        setCurrentQuestion('');
      }
  
      // Resume recording
      resumeRecording();
    };

  /**
   * Plays the assistant's audio prompt.
   *
   * @param {string} audioUrl - URL of the audio file to play.
   * @param {number} [retryCount=0] - Current retry attempt for playing audio.
   */
  const playAssistantAudio = async (audioUrl, retryCount = 0) => {
    console.log('Playing assistant audio:', audioUrl);
    if (!audioPlayerRef.current) {
      console.warn('Audio player ref is not set.');
      return;
    }

    let fullAudioUrl;

    // Function to check if the URL is absolute
    const isAbsoluteUrl = (url) => {
      return /^(?:[a-z]+:)?\/\//i.test(url);
    };

    if (isAbsoluteUrl(audioUrl)) {
      // Use the absolute URL as is
      fullAudioUrl = audioUrl;
    } else {
      // Prepend backend URL for relative paths
      if (!audioUrl.startsWith('/')) {
        audioUrl = '/' + audioUrl;
      }
      fullAudioUrl = `${backendUrl}${audioUrl}`;
    }

    console.log('Full audio URL:', fullAudioUrl);

    try {
      // Load the audio source
      audioPlayerRef.current.src = fullAudioUrl;

      // Play the audio
      const playPromise = audioPlayerRef.current.play();
      if (playPromise !== undefined) {
        await playPromise;
        console.log('Audio playback started.');
        if (onAudioPlayingChange) {
          onAudioPlayingChange(true);
        }
        if (onListeningChange) {
          onListeningChange(false);
        }
      }
    } catch (error) {
      console.error('Error loading or playing audio:', error);
      if (retryCount < 5) {
        const delay = Math.pow(2, retryCount) * 1000;
        console.log(`Retrying to load audio in ${delay / 1000} seconds...`);
        setTimeout(() => {
          playAssistantAudio(audioUrl, retryCount + 1);
        }, delay); // Exponential backoff
      } else {
        alert('Failed to load audio after multiple attempts.');
      }
    }

    // Set up event listeners
    audioPlayerRef.current.onended = handleAudioEnded;

    audioPlayerRef.current.onplay = () => {
      if (onAudioPlayingChange) {
        onAudioPlayingChange(true);
      }
      if (onListeningChange) {
        onListeningChange(false);
      }
    };

    audioPlayerRef.current.onpause = () => {
      if (onAudioPlayingChange) {
        onAudioPlayingChange(false);
      }
    };
  };

  /**
   * Handles the end of the assistant's audio playback.
   */
  const handleAudioEnded = () => {
    console.log('Assistant audio playback finished.');
    if (onAudioPlayingChange) {
      onAudioPlayingChange(false);
    }

    // Check if the interview is complete
    if (isInterviewComplete) {
      console.log('Interview is complete. Not starting recording again.');
      return; // Do not start recording again
    }

    // Notify backend to prepare for new recording
    if (socketManagerRef.current) {
      socketManagerRef.current.restartRecording();
      console.log("Sent 'restart_recording' event to backend.");
    }

    // Start recording after the assistant's audio ends
    startRecordingAfterPrompt();
  };

  /**
   * Converts Float32 audio data to 16-bit PCM.
   *
   * @param {Float32Array} input - The audio data in Float32 format.
   * @returns {ArrayBuffer} - The audio data in 16-bit PCM format.
   */
  const floatTo16BitPCM = (input) => {
    const buffer = new ArrayBuffer(input.length * 2);
    const output = new DataView(buffer);
    for (let i = 0; i < input.length; i++) {
      let s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
    return buffer;
  };

  /**
   * Starts the interview process by initializing the Socket.IO connection.
   */
  const startProcess = () => {
    if (!isInterviewStarted) {
      setIsInterviewStarted(true);
      initializeSocket();
      console.log('Started interview process.');
    }
  };

  /**
   * Stops the interview process by stopping the recording and cleaning up resources.
   */
  const stopProcess = () => {
    if (isInterviewStarted) {
      stopRecording();
      setIsInterviewStarted(false);
      console.log('Stopped interview process.');
    }
  };

  /**
   * Starts the interview process when allowedUsers is set.
   */
  useEffect(() => {
    if (autoStart && allowedUsers && !isInterviewStarted) {
      console.log('Allowed users is set. Starting the process.');
      startProcess();
    } else {
      console.log(
        'Not starting process automatically. autoStart:',
        autoStart,
        'allowedUsers:',
        allowedUsers,
        'isInterviewStarted:',
        isInterviewStarted
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedUsers]);
  

  /**
   * Cleans up the interview process when the component using this hook unmounts.
   */
  useEffect(() => {
    return () => {
      if (isInterviewStarted) {
        stopProcess();
        console.log('Cleaned up interview process on unmount.');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Runs once on unmount

  /**
   * Sends text data to the backend via SocketManager.
   *
   * @param {string} text - The text input from the user.
   */
  const sendTextData = (text) => {
    if (socketManagerRef.current) {
      socketManagerRef.current.sendTextData(text);
      console.log('Text data sent:', text);
      setShowTextInput(false); // Hide the text input field after sending
    } else {
      console.warn('SocketManager is not initialized. Cannot send text data.');
    }
  };

  

  return {
    audioPlayerRef,
    videoPlayerRef, // Expose videoPlayerRef for the Video component
    isInitializing,
    startProcess,
    stopProcess,
    sendTextData, // Expose the sendTextData method
    showTextInput, // Expose showTextInput state
    setShowTextInput, // Expose setShowTextInput method
    socket: socketManagerRef.current ? socketManagerRef.current.socket : null,
    pauseRecording,
    resumeRecording, // Expose the resumeRecording function
    interruptAgent,
    initializeAudioContextAndMic,
    manualStopRecording,
  };
};

export default useAudioEngine;
