// VideoPlayer.js

import React, { useEffect, forwardRef } from 'react';

const VideoPlayer = forwardRef(({ videoData, onVideoEnded }, ref) => {

  useEffect(() => {
    if (videoData && ref.current) {
      const { video_url, start_time, end_time } = videoData;
      const videoPlayer = ref.current;
      console.log(`Setting video source to: ${video_url}`);

      // Reset the video player
      videoPlayer.pause();
      videoPlayer.currentTime = 0;
      videoPlayer.src = ''; // Reset the video source
      videoPlayer.load();

      // Define event handlers
      const onLoadedMetadata = () => {
        console.log('Video metadata loaded');
        videoPlayer.currentTime = start_time || 0;
        console.log(`Starting playback at ${start_time} seconds`);
        videoPlayer.play()
          .then(() => {
            console.log('Video playback started');
          })
          .catch((error) => {
            console.error('Error starting video playback:', error);
          });
      };

      const timeUpdateHandler = () => {
        if (videoPlayer.currentTime >= end_time) {
          videoPlayer.pause();
          console.log(`Paused playback at ${end_time} seconds`);
          videoPlayer.removeEventListener('timeupdate', timeUpdateHandler);
          if (onVideoEnded) {
            onVideoEnded();
          }
        }
      };

      const onPause = () => {
        console.log('Video playback paused.');
        if (videoPlayer.currentTime < end_time) {
          console.log('Video was paused before end_time.');
          videoPlayer.removeEventListener('timeupdate', timeUpdateHandler);
          if (onVideoEnded) {
            onVideoEnded();
          }
        }
      };

      const onEnded = () => {
        console.log('Video playback ended.');
        videoPlayer.removeEventListener('timeupdate', timeUpdateHandler);
        if (onVideoEnded) {
          onVideoEnded();
        }
      };

      const onError = (e) => {
        console.error('Video failed to load:', e);
      };

      // Add event listeners
      videoPlayer.addEventListener('loadedmetadata', onLoadedMetadata);
      videoPlayer.addEventListener('timeupdate', timeUpdateHandler);
      videoPlayer.addEventListener('pause', onPause);
      videoPlayer.addEventListener('ended', onEnded);
      videoPlayer.addEventListener('error', onError);

      // Set the video source after adding event listeners
      videoPlayer.src = video_url;

      // Clean up event listeners when videoData changes or component unmounts
      return () => {
        videoPlayer.removeEventListener('loadedmetadata', onLoadedMetadata);
        videoPlayer.removeEventListener('timeupdate', timeUpdateHandler);
        videoPlayer.removeEventListener('pause', onPause);
        videoPlayer.removeEventListener('ended', onEnded);
        videoPlayer.removeEventListener('error', onError);
      };
    }
  }, [videoData, onVideoEnded, ref]);

  return (
    <div>
      <video
        ref={ref}
        controls
        style={{ width: '600px', height: '400px' }}
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
});

export default VideoPlayer;
