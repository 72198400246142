// ./AudioRecorder.js
import React, { useEffect, useState } from 'react';
import AudioVisualization from './Components/AudioVisualization';
import useAudioEngine from './Components/AudioEngine';
import VideoPlayer from './Components/VideoPlayer';
import RatingComponent from './Components/RatingComponent';
import EmailCapture from './Components/EmailCapture'; // Import the EmailCapture component

const generateRandomScale = () => {
  const scaleStart = Math.random() * 0.3 + 0.3;
  const scaleEnd = Math.random() * 0.5 + 0.5;
  return { scaleStart, scaleEnd };
};

const AudioRecorder = ({ link = 'b1af8a91-a47a-4003-bef2-244f3bc9f064', clientKey }) => {
  const [transcription, setTranscription] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [isProcessEnded, setIsProcessEnded] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [isProcessRunning, setIsProcessRunning] = useState(false);
  const [userName, setUserName] = useState('DefaultUser');
  const [allowedUsers, setAllowedUsers] = useState(['DefaultUser']);
  const [videoData, setVideoData] = useState(null);
  const [interviewType, setInterviewType] = useState(null);
  const [isMicInitialized, setIsMicInitialized] = useState(false);
  const [showEmailCapture, setShowEmailCapture] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [backendMessage, setBackendMessage] = useState('');
  const backendUrl =  'https://demo-runner-service-test-421658591024.us-central1.run.app'; // Replace with your actual backend URL
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const bars = Array.from({ length: 4 }, (_, i) => ({
    ...generateRandomScale(),
    delay: i * 0.1,
  }));

  const {
    audioPlayerRef,
    videoPlayerRef,
    isInitializing: audioEngineInitializing,
    startProcess,
    stopProcess,
    sendTextData,
    showTextInput,
    setShowTextInput,
    resumeRecording,
    interruptAgent,
    initializeAudioContextAndMic,
    socket,
    manualStopRecording,
  } = useAudioEngine({
    clientKey,
    userName,
    allowedUsers,
    link,
    onTranscriptionUpdate: (text) => {
      setTranscription((prev) => prev + ' ' + text);
      console.log('[AudioRecorder] Transcription updated:', text);
    },
    onProcessEnd: () => {
      setIsProcessEnded(true);
      setIsProcessRunning(false);
      setIsButtonPressed(false); // Reset the button state here
      console.log('[AudioRecorder] Process ended.');
    },
    onAudioPlayingChange: (isPlaying) => {
      setIsAudioPlaying(isPlaying);
      console.log('[AudioRecorder] isAudioPlaying set to:', isPlaying);
      if (!isPlaying && isButtonPressed) {
        setIsButtonPressed(false); // Optionally reset when audio stops playing
      }
    },
    onListeningChange: (isListeningState) => {
      setIsListening(isListeningState);
      console.log('[AudioRecorder] isListening set to:', isListeningState);
    },
    setCurrentQuestion,
    backendUrl: `${backendUrl}/api/audio`,
    onTextResponse: (data) => {
      console.log('[AudioRecorder] Text response from backend:', data);
      setBackendMessage(data.message || 'Text received successfully.');
    },
    onPlayVideo: (data) => {
      console.log('[AudioRecorder] Received play_video event:', data);
      setVideoData(data);
    },
    setVideoData,
    autoStart: false,
    interviewType,
  });

  useEffect(() => {
    setIsInitializing(audioEngineInitializing);
    console.log('[AudioRecorder] isInitializing set to:', audioEngineInitializing);
  }, [audioEngineInitializing]);

  const isAgentSpeaking = isAudioPlaying || videoData !== null;

  const handleSubmitText = () => {
    if (textInput.trim() === '') {
      alert('Please enter some text.');
      return;
    }
    sendTextData(textInput.trim());
    setTextInput('');
    console.log('[AudioRecorder] Text submitted:', textInput.trim());
  };

  const handleInitializeMic = async () => {
    try {
      await initializeAudioContextAndMic();
      console.log('AudioContext and microphone initialized.');
      setIsMicInitialized(true);
    } catch (e) {
      console.error('Failed to initialize AudioContext and microphone:', e);
      alert('Microphone access is required to proceed.');
    }
  };

  const handleStartProcessWithType = (type) => {
    setInterviewType(type);
    setIsProcessRunning(true);
    setShowEmailCapture(true);
    setHasStarted(true); // Set that the process has started
    console.log('[AudioRecorder] Process started with interview_type:', type);
  };

  useEffect(() => {
    if (interviewType && isProcessRunning) {
      startProcess();
    }
  }, [interviewType]);

  const handleStopProcess = () => {
    stopProcess();
    setIsProcessRunning(false);
    setIsProcessEnded(true);
    setIsButtonPressed(false); // Ensure button state is reset
    console.log('[AudioRecorder] Process stopped.');
  };

  const handleInterruption = () => {
    if (interruptAgent) {
      interruptAgent();
      console.log('[AudioRecorder] Agent interrupted.');
    } else {
      console.warn('interruptAgent is undefined.');
    }
  };

  const handleFeedbackSubmit = (feedback) => {
    console.log('Feedback received:', feedback);
    setIsProcessEnded(false);
    setIsProcessRunning(false);
    setShowThankYou(true);
  };

  const isMobileDevice = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  // New state to determine if the user has started the process
  const [hasStarted, setHasStarted] = useState(false);

  // Define container styles
  const containerStyle = {
    width: '100%',
    height: '100vh', // Ensure full viewport height
    overflowY: 'hidden', // Prevent scrolling on the main container
    backgroundColor: '#f0f0f0',
    display: 'flex',
    flexDirection: isMobileDevice() ? 'column' : 'row', // Adjust based on device
    alignItems: 'stretch', // Equal height for both sides
    justifyContent: 'center', // Center content horizontally
    boxSizing: 'border-box',
    margin: '0',
    padding: '0',
  };

  // Styles for pre-start mode: centered container with start buttons
  const preStartContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%', // Full height
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px',
    textAlign: 'center',
  };

  // Styles for process mode - main content (Left - adjustable width)
  const mainContentStyle = {
    flex: isMobileDevice() ? 'none' : 7, // Do not flex on mobile
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', // Center content vertically
    padding: '20px', // Retain padding for internal spacing
    boxSizing: 'border-box',
    backgroundColor: '#f0f0f0',
    overflowY: 'auto',
    height: isMobileDevice() ? '50vh' : '100%', // 50% height on mobile
    width: '100%', // Ensure full width on mobile
  };

  // Styles for email capture container (Right - adjustable width)
  const emailCaptureContainerStyle = {
    flex: isMobileDevice() ? 'none' : 3, // Do not flex on mobile
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    margin: '0',
    padding: '0',
    border: 'none',
    height: isMobileDevice() ? '50vh' : '100%', // 50% height on mobile
    width: '100%', // Ensure full width on mobile
  };

  const handleStopRecording = () => {
    setIsButtonPressed(true);
    manualStopRecording();
    console.log('[AudioRecorder] Manual stop recording invoked.');
  };

  // Add the useEffect hook here
  useEffect(() => {
    if (!isAudioPlaying && isButtonPressed) {
      setIsButtonPressed(false);
      console.log('[AudioRecorder] isButtonPressed reset to false after audio playback ended.');
    }
  }, [isAudioPlaying]);

  // Responsive adjustments using media queries
  const responsiveStyles = `
    @media (max-width: 768px) {
      .container {
        flex-direction: column;
      }
      .main-content-container, .email-capture-container {
        flex: none;
        width: 100%;
        height: 50vh; /* Each component takes half the screen */
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  `;

  const contentWrapperStyle = {
    width: '100%',
    maxWidth: '600px', // Limit width on larger screens
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const visualizationContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  };

  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'column', // Align buttons vertically by default
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const distinctButtonStyles = [
    { backgroundColor: '#007BFF' }, // Clean, vibrant blue
    { backgroundColor: '#6F42C1' }, // Clean, elegant purple
  ];

  const textSectionStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const textInputContainerStyle = {
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const textareaStyle = {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    resize: 'vertical',
    boxSizing: 'border-box',
  };

  const backendMessageStyle = {
    width: '100%',
    maxWidth: '600px',
    padding: '10px 20px',
    backgroundColor: '#d4edda',
    color: '#155724',
    borderRadius: '4px',
    border: '1px solid #c3e6cb',
    boxSizing: 'border-box',
    textAlign: 'center',
    marginTop: '10px',
  };

  const thankYouStyle = {
    marginTop: '50px',
    textAlign: 'center',
    color: '#28a745',
  };

  const feedbackContainerStyle = {
    width: '100%',
    maxWidth: '800px',
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  // Helper function to get start process button styles based on disabled state
  const getStartButtonStyle = (index, disabled) => ({
    ...buttonStyle,
    ...distinctButtonStyles[index],
    backgroundColor: disabled ? '#6c757d' : distinctButtonStyles[index].backgroundColor, // Grey if disabled
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.6 : 1,
  });

  // Updated button styles including disabled state
  const buttonStyle = {
    margin: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    transition: 'background-color 0.3s ease, opacity 0.3s ease, cursor 0.3s ease',
  };

  return (
    <div style={containerStyle} className="container">
      {/* Inject responsive styles */}
      <style>{responsiveStyles}</style>

      {/* Pre-Start Mode: Show only start buttons centered */}
      {!hasStarted && !isProcessRunning && !isProcessEnded && !showThankYou && (
        <div style={preStartContainerStyle}>
          <h2 style={{ marginBottom: '20px' }}>Welcome to the session with AI product expert</h2>
          <div style={buttonContainerStyle}>
            <button
              onClick={() => handleStartProcessWithType('demo')}
              style={getStartButtonStyle(0, isMobileDevice() && !isMicInitialized)}
              disabled={isMobileDevice() && !isMicInitialized}
            >
              I want to learn more about the product
            </button>
            <button
              onClick={() => handleStartProcessWithType('question')}
              style={getStartButtonStyle(1, isMobileDevice() && !isMicInitialized)}
              disabled={isMobileDevice() && !isMicInitialized}
            >
              I have a specific question about the product
            </button>
          </div>
          {/* Show microphone initialization button if not initialized and on mobile */}
          {!isMicInitialized && isMobileDevice() && (
            <button
              onClick={handleInitializeMic}
              style={{ ...buttonStyle, backgroundColor: '#28a745', marginTop: '20px' }} // Green button
            >
              Enable Audio
            </button>
          )}
        </div>
      )}

      {/* Process Mode: Show main content and email capture */}
      {hasStarted && !isProcessEnded && !showThankYou && (
        <>
          {/* Main Content */}
          <div style={mainContentStyle} className="main-content-container">
            {/* Visualization and Text Section */}
            <div style={contentWrapperStyle}>
              {/* Audio/Video Visualization */}
              <div style={visualizationContainerStyle}>
                {videoData ? (
                    <VideoPlayer
                      ref={videoPlayerRef}
                      videoData={videoData}
                      onVideoEnded={() => {
                        console.log('[AudioRecorder] Video playback ended.');
                        setVideoData(null);
                        setBackendMessage(''); // Clear the previous backend message

                        // Notify the backend that the video has ended to trigger the next assistant response
                        if (socket && socket.connected) {
                          socket.emit('video_ended');
                          console.log("Sent 'video_ended' event to backend to trigger next assistant response.");
                        } else {
                          console.warn('Socket is undefined or not connected in AudioRecorder.');
                        }
                      }}
                    />

                ) : (
                  <AudioVisualization
                    isInitializing={isInitializing}
                    isAudioPlaying={isAudioPlaying}
                    isListening={isListening}
                    bars={bars}
                    currentQuestion={currentQuestion}
                    isProcessEnded={isProcessEnded}
                  />
                )}
              </div>

              {/* Show microphone initialization button if not initialized and on mobile */}
              {!isMicInitialized && isMobileDevice() && (
                <button
                  onClick={handleInitializeMic}
                  style={{ ...buttonStyle, backgroundColor: '#28a745' }} // Green button
                >
                  Enable Audio
                </button>
              )}

              {/* Start, Stop, and Interrupt Buttons */}
              <div
                style={
                  !isProcessRunning
                    ? { ...buttonContainerStyle, flexDirection: 'column' }
                    : { ...buttonContainerStyle, flexDirection: 'row' }
                }
              >
                {isProcessRunning ? (
                  <>
                    {isAgentSpeaking && (
                      <button
                        onClick={handleInterruption}
                        style={{ ...buttonStyle, backgroundColor: '#ffc107', color: '#212529' }}
                      >
                        Interrupt
                      </button>
                    )}
                    {isListening && (
                      <button
                        onClick={handleStopRecording}
                        style={{
                          ...buttonStyle,
                          backgroundColor: isButtonPressed ? '#341e7e' : '#28a745', // Darker green when pressed
                          cursor: isButtonPressed ? 'not-allowed' : 'pointer', // Disable cursor when pressed
                        }}
                        disabled={isButtonPressed} // Disable button after it is pressed
                      >
                        {isButtonPressed ? "Processing your response" : 'Done Speaking'} {/* Update text when pressed */}
                      </button>
                    )}
                    <button
                      onClick={handleStopProcess}
                      style={{ ...buttonStyle, backgroundColor: '#dc3545' }}
                    >
                      Stop Demo
                    </button>
                  </>
                ) : null}
              </div>

              {/* Text Input and Backend Messages */}
              <div style={textSectionStyle}>
                {/* Text Input Field */}
                {showTextInput && (
                  <div style={textInputContainerStyle}>
                    <textarea
                      value={textInput}
                      onChange={(e) => setTextInput(e.target.value)}
                      placeholder="Enter your text here..."
                      style={textareaStyle}
                    />
                    <button
                      onClick={handleSubmitText}
                      style={{ ...buttonStyle, backgroundColor: '#007BFF' }} // Blue button
                    >
                      Send Text
                    </button>
                  </div>
                )}

                {/* Display Backend Messages */}
                {backendMessage && <div style={backendMessageStyle}>{backendMessage}</div>}
              </div>
            </div>

            {/* Audio Element */}
            <audio ref={audioPlayerRef} />
          </div>

          {/* Email Capture */}
          {showEmailCapture && (
            <div className="email-capture-container" style={emailCaptureContainerStyle}>
              <EmailCapture pageOrigin={clientKey} />
            </div>
          )}
        </>
      )}

      {/* Process Ended and Thank You Message */}
      {(isProcessEnded || showThankYou) && (
        <div style={mainContentStyle} className="main-content-container">
          {showThankYou ? (
            <div style={thankYouStyle}>
              <h2>Thank you for your feedback!</h2>
            </div>
          ) : (
            <div style={feedbackContainerStyle}>
              <RatingComponent onSubmit={handleFeedbackSubmit} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
